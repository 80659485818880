import {
  getPriceFormattedNoDecimal,
  formatNonDecimalNumber,
} from "../../utils/currency";

import i18n from "@/i18n.js";

import {
  getAllUserReturns,
  getVehicleByPlate,
  getBrands,
  getModels,
  getVersions,
  getValuation,
  saveValuation,
  getCountRetake,
  deleteRetake,
} from "../../api/retake";

const processMonth = (month) => {
  if (typeof month == "string") return month;
  return month < 10 ? "0" + month : month;
};

const initialState = {
  successfullPlateCall: false,
  prefill: false,
  plate: "",
  brandId: "",
  brand: "",
  brands: [],
  model: "",
  models: [],
  version: "",
  versions: [],
  kilometers: "",
  plateDate: "",
  bcaId: "",
  fuel: "",
  fuelId: "",
  fuels: [],
  vin: "",
  transmission: "",
  transmissionId: "",
  month: 0,
  year: 0,
  tradeInId: "",
  valuation: 0.0,
  userRetakesCount: 0,
  weeklyLimit: false,
};

const state = {
  ...initialState,
  retakes: [],
};

const getExpirationDate = (date) => {
  date = new Date(date);
  const daysToExpire = 30;
  date.setDate(date.getDate() + daysToExpire);
  const expirationDate = new Date(date);

  return expirationDate.toLocaleDateString("pt-PT");
}

const getters = {
  getRetakes: (state) => {
    const newState = state.retakes.map((retake) => {
      var vehicleDetails = {
        id: retake.id,
        plate: retake.licensePlate,
        brand: retake.brand,
        model: retake.model,
        plateMonth: retake.month,
        plateYear: retake.year,
        fuel: retake.fuel,
        kilometers: formatNonDecimalNumber(retake.kilometers),
        estimatedValue: getPriceFormattedNoDecimal(retake.initialValuation),
        realValue: getPriceFormattedNoDecimal(retake.actualValuation),
        retakeStake: retake.status,
        expDate: getExpirationDate(retake.createTime),
        consignment: retake.consignment === 1,
        dealerId: retake.dealerId
      };
      return vehicleDetails;
    });

    return newState;
  },
  getPlate: (state) => state.plate,
  getYear: (state) => state.year,
  getMonth: (state) => state.month,
  getBrand: (state) => state.brand,
  getBrands: (state) => state.brands,
  getModel: (state) => state.model,
  getModels: (state) => state.models,
  getVersion: (state) => state.version,
  getVersions: (state) => state.versions,
  getKilometers: (state) => state.kilometers,
  getFuel: (state) => state.fuel,
  getFuels: (state) => state.fuels,
  getPlateDate: (state) => state.plateDate,
  getBcaId: (state) => state.bcaId,
  getSuccessfullPlateCall: (state) => state.successfullPlateCall,
  getRetakeValue: (state) => state.valuation,
  getRetake: (state) => {
    if (!state.valuation) return null;
    return {
      plate: state.plate,
      brand: state.brand,
      value: getPriceFormattedNoDecimal(state.valuation),
      pvp: getPriceFormattedNoDecimal(state.valuation),
    };
  },
  getRetakeObj: (state) => {
    const { model, valuation, plate } = state;
    return {
      model,
      value: valuation,
      registration: plate,
      expire: 2,
    };
  },
  getRetakesCount: (state) => state.userRetakesCount,
  getWeeklyLimit: (state) => state.weeklyLimit,
};

const mutations = {
  setUserRetakes(state, retakes) {
    state.retakes = retakes;
  },
  setMonth(state, val) {
    state.month = val;
  },
  setYear(state, val) {
    state.year = val;
  },
  setSuccessfullPlateCall(state, val) {
    state.successfullPlateCall = val;
  },

  setAllVersions(state, versions) {
    state.allVersions = versions;
  },
  setPrefillBrand(state, { brand, brandId }) {
    state.brand = { key: brandId, value: brand, text: brand, selected: true };
    state.brands = [state.brand];
  },
  setPlate(state, plate) {
    state.plate = plate.toUpperCase();
  },
  setBrand(state, brand) {
    state.brand = brand;
    state.brands = state.brands.length
      ? state.brands.map((el) => {
        return {
          key: el.key,
          value: el.value,
          text: el.text,
          selected: brand.value === el.value,
        };
      })
      : [];
  },
  setBrandId(state, brandId) {
    state.brandId = brandId;
  },
  setBrandById(state, id) {
    state.brand = state.brands.find((b) => b.value == id);
  },
  setBrands(state, brands) {
    state.brands = [
      ...brands.map((el) => ({
        key: el.id,
        value: el.description,
        text: el.description,
      })),
    ];
  },
  setModel(state, model) {
    state.model = model;
    state.models = state.models.length
      ? state.models.map((el) => {
        return {
          key: el.key,
          value: el.value,
          text: el.text,
          selected: model.value === el.value,
        };
      })
      : [];
  },
  setModels(state, models) {
    state.models =
      models?.length > 0
        ? models.map((el) => ({
          value: el.model,
          key: el.model,
          text: el.model,
          selected: models.length === 1,
        }))
        : [];
    if (models.length === 1) {
      state.model = models[0].model;
      this.commit("RetakeModule/setVersions", models[0]?.versions);
    }
  },
  setVersion(state, version) {
    state.version = version;
    state.versions = state.versions.length
      ? state.versions.map((el) => {
        return {
          key: el.key,
          value: el.value,
          text: el.text,
          selected: version === el.value,
          fuel: el.fuel,
          fuelId: el.fuelId,
          transmission: el.transmission,
          transmissionId: el.transmissionId,
        };
      })
      : [];

    // set options for fuel
    this.commit(
      "RetakeModule/setFuels",
      state.versions.map((v) => ({
        text: v.fuel,
        value: v.fuel,
        selected: v.fuel == state.fuel,
      }))
    );
  },
  setVersions(state, versions) {
    state.versions =
      versions?.length > 0
        ? versions.map((el) => ({
          value: el.version,
          key: el.versionId,
          text: el.version,
          selected: versions.length === 1,
          fuel: el.fuel,
          fuelId: el.fuelId,
          transmission: el.transmission,
          transmissionId: el.transmissionId,

        }))
        : [];

    // set options for fuel
    let fuels = []
    if (state.versions.length)
      fuels = state.versions.map((v) => ({
        text: v.fuel,
        value: v.fuel,
        selected: v.fuel == state.fuel,
      }));
    this.commit(
      "RetakeModule/setFuels",
      fuels
    );

    if (versions.length === 1) {
      state.bcaId = versions[0].versionId;
      state.version = versions[0].version;
      state.fuel = versions[0].fuel;
      state.transmission = versions[0].transmission;
    }
  },
  setPlateDate(state, plateDate) {
    state.plateDate = plateDate;
  },
  setKilometers(state, kilometers) {
    state.kilometers = kilometers;
  },
  setBcaId(state, bcaId) {
    state.bcaId = bcaId;
  },
  setFuel(state, fuel) {
    state.fuel = fuel.value;
    state.fuelId = fuel.key;
    state.fuels = state.fuels.length
      ? state.fuels.map((el) => {
        return {
          value: el.value,
          text: el.text,
          selected: fuel.value === el.value,
          key: fuel.key,
        };
      })
      : [];
  },
  setFuels(state, fuels) {
    // remove duplicates
    state.fuels = fuels.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.key === value.key)
    );
  },
  setTransmission(state, transmission) {
    state.transmission = transmission.value;
    state.transmissionId = transmission.key;

  },
  setVin(state, vin) {
    state.vin = vin;
  },
  setValuation(state, valuationResponse) {
    state.valuation = valuationResponse.valuation;
    state.tradeInId = valuationResponse.tradeInId;
  },
  setValuationRequest(state, valuationRequest) {
    state.valuationRequest = valuationRequest;
  },
  setRetake(state, retake) {
    state.plate = retake.licensePlate;
    state.brand = retake.brand;
    state.model = retake.model;
    state.version = retake.version;
    state.kilometers = retake.kilometers;
    state.bcaId = retake.bcaId;
    state.valuation =
      retake.status === 1 ? retake.actualValuation : retake.initialValuation;
    state.status = retake.status;
    state.id = retake.id;
    state.plateDate = `${retake.year}-${processMonth(retake.month)}-01`;
  },
  setInitialState(state) {
    for (var [key, value] of Object.entries(state)) {
      value;
      if (key !== "userRetakesCount") state[key] = initialState[key];
    }
  },
  setRetakeCount(state, count) {
    state.userRetakesCount = count;
  },
  setWeeklyLimit(state, isReached) {
    state.weeklyLimit = isReached;
  },
};

const actions = {
  async storeGetAllRetakes({ commit }) {
    commit("SpinnerModule/setLoading", true, { root: true });
    try {
      const response = await getAllUserReturns();
      commit("setUserRetakes", response.data.data);
    } catch (e) {
      commit("SpinnerModule/setLoading", false, { root: true });
    }
    commit("SpinnerModule/setLoading", false, { root: true });
  },
  async storeVehicleByPlate({ commit, state }) {
    try {
      commit("SpinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
      //setDefaults(commit);
      const plate = state.plate;
      const res = await getVehicleByPlate(state.plate);

      if (
        res?.data?.data?.brand !== null ||
        res?.data?.data?.model?.model !== null
      ) {
        const [year, month] = res.data.data.registrationDate
          .split("-")
          .slice(0, 2);
        commit("setYear", year);
        commit("setMonth", month);
        commit("setSuccessfullPlateCall", true);
        commit("setPrefillBrand", {
          brand: res.data.data.brand,
          brandId: res.data.data.brandId,
        });

        const model = res.data.data.models[0];
        commit("setModels", res.data.data.models);

        if (res.data.data.models.length === 1) {
          commit("setModel", {
            key: model.modelId,
            value: model.model,
            text: model.model,
            selected: true,
          });
          commit("setVersions", model.versions);
          if(model.versions.length === 1){
            commit("setFuel", {
              key: model.versions[0].fuelId,
              value: model.versions[0].fuel,
            })
            commit("setTransmission", {
              key: model.versions[0].transmissionId,
              value: model.versions[0].transmission,
            })
          }
        }
        commit("setAllVersions", res.data.data.models)

        commit("setVin", res.data.data.vin);
      } else {
        commit("setInitialState");
        commit("setPlate", plate);
        const resBrands = await getBrands();
        commit("setBrands", resBrands.data.data);
      }
    } catch (err) {
      const resBrands = await getBrands();
      //setDefaults(commit);
      commit("setBrands", resBrands.data.data);
      commit(
        "setAlert",
        {
          message: i18n.t("toast.valuation.error.generic"),
          type: "error",
          display: true,
          global: true,
        },
        { root: true }
      );
    } finally {
      commit("SpinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
  async storeBrands({ commit }) {
    try {
      const res = await getBrands();
      //setDefaults(commit);
      commit("setBrands", res.data.data);
    } catch (err) {
      console.error("Could not fetch brands from backend");
    }
  },
  async storeModels({ commit, state }) {
    commit("SpinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
    try {
      const res = await getModels(state.brand.key, state.year);
      commit("setModels", res.data.data);
    } catch (err) {
      console.error("Could not fetch models from backend");
    } finally {
      commit("SpinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
  async storeVersions({ commit, state }) {
    commit("SpinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
    try {
      let versions = await state.allVersions.filter((v) => v.model === state.model.value)[0].versions;
      if(!versions){
        const res = await getVersions(
          state.brand.key,
          state.model.key,
          state.year
        );
        versions = res.data.data;
      }
      commit("setVersions", versions);
    } catch (err) {
      console.error("Could not fetch versions from backend");
    } finally {
      commit("SpinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
  async storeValuation({ state }, userInfo) {
    const response = await getValuation({
      plate: state.plate,
      brand: state.brand.value,
      brandId: state.brand.key,
      model: state.model.value,
      modelId: state.model.key,
      version: state.version,
      kilometers: state.kilometers,
      plateDate: `${state.year}-${processMonth(state.month)}-01`,
      versionId: state.bcaId,
      firstName: userInfo.name.split(" ")[0],
      lastName: userInfo.name.split(" ")[1],
      emailAddress: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      dealerId: userInfo.dealerId,
      fuel: state.fuel,
      fuelId: state.fuelId,
      transmission: state.transmission,
      transmissionId: state.transmissionId,
      vin: state.vin,
      tradeInType: userInfo.tradeInType,
      assignToUser: true,
    });
    return response;
  },
  async assignValuationToUser({ state }) {
    try {
      await saveValuation({ retakeId: state.retakeId });
    } catch (err) {
      console.error("Could not assign valuation to user");
    }
  },
  cleanRetake({ commit }) {
    commit("setInitialState");
  },
  async storeUserRetakesCount({ commit }) {
    try {
      const res = await getCountRetake();
      commit("setRetakeCount", res.data.data);
    } catch (err) {
      console.error("Could not fetch users retake count from backend");
    }
  },
  async storeDeleteRetake({ commit, dispatch }, id) {
    commit("SpinnerModule/setLoading", false, { root: true });
    try {
      await deleteRetake(id);
      dispatch("storeGetAllRetakes");
    } catch (e) {
      commit("SpinnerModule/setLoading", false, { root: true });
    }
    commit("SpinnerModule/setLoading", false, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
